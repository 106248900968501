import styled from 'styled-components';

export const StickyWrapper = styled.div`
  position: fixed;
  top: 55px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  transition: transform 0.3s ease;
  z-index: 25;
  width: 100%;

  ${props =>
    props.isSticky &&
    `
    transform: translateY(-110%);
    box-shadow: none;
  `}
`;
