import styled from 'styled-components';

const Section = styled.section`
  ${props => {
    if (props.sm) {
      return `
      margin-bottom: 0.5em;
  `;
    } else if (props.md) {
      return `
      margin-bottom: 1em;
  `;
    } else if (props.lg) {
      return `
    margin-bottom: 2em;
  `;
    } else if (props.xl) {
      return `
    margin-bottom: 8em;
  `;
    }
  }}
`;

export default Section;
