import styled from 'styled-components';

const ScrollWrap = styled.div`
  padding: 0em 2em 1em 2em;
  overflow-y: scroll;
  height: calc(100vh - 69px);
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #cbcbcb;
    border-radius: 4px;
  }
`;

export default ScrollWrap;
