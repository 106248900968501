import _isEmpty from 'lodash/isEmpty';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from '../Ant';
import { Container } from '../Container';
import AuthMenu from './AuthMenu';
import { HeaderWrapper, Logo } from './styles';

const Header = ({ provider }) => {
  return (
    <HeaderWrapper>
      <Container>
        <Row align="top">
          <Col flex="200px" style={{ overflow: 'visible', height: '55px' }}>
            {_isEmpty(provider) ? (
              <Link href="/">
                <a>
                  <Logo>
                    <img src="/images/shared/logos/goatmascot.png" alt="The RoadGoat goat" />
                    <img src="/images/shared/logos/logo.png" alt="RoadGoat" height="50px" />
                  </Logo>
                </a>
              </Link>
            ) : (
              <Logo>
                <img src="/images/shared/logos/goatmascot.png" alt="The RoadGoat goat" />
                <img src="/images/shared/logos/logo.png" alt="RoadGoat" height="50px" />
              </Logo>
            )}
          </Col>

          {_isEmpty(provider) && (
            <Col flex="auto">
              <Row justify="end">
                <AuthMenu onMap={false} />
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  provider: PropTypes.func,
};

export default Header;
