import styled from 'styled-components';

const IconCircle = styled.span`
  display: inline-flex;
  border-radius: 50%;
  background-color: ${props => props.bg};
  color: ${props => props.color};
  width: 1.4285em;
  height: 1.4285em;
  align-items: center;
  justify-content: center;
  font-size: ${props => (props.size ? props.size : '14px')};
  margin-right: 0.25em;
  vertical-align: bottom;
`;

export default IconCircle;
