import styled from 'styled-components';
import { Layout, Menu, Col } from '../Ant';
import { IconCircle as StyledIconCircle } from '../Container';

const { Header } = Layout;
const { Item, Divider } = Menu;

export const HeaderWrapper = styled(Header)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  padding: 0;
  background-color: ${props => props.theme.color.primary};
  z-index: 30;
`;

export const HeaderOverlayWrapper = styled.div`
  width: auto;
  height: 55px;
  background-color: ${props => props.theme.color.primary};
  padding: 0 1em;
  display: flex;
  overflow: hidden;
  border-radius: 0 0 15px 15px;
  margin-bottom: 1em;
  position: relative;
  z-index: 2;
`;

export const HeaderWrapperBiz = styled(Header)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  padding: 0;
  background-color: ${props => props.theme.color.business};
  z-index: 100;
`;

export const StyledHeader = styled(Menu)`
  display: inline-block;
  white-space: nowrap;
  margin: 0 auto;
  height: 55px;
  width: 100%;
  top: 0;
  z-index: 1;
  max-width: 1000px;

  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    width: 933px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Logo = styled.div`
  z-index: 26;
  display: inline-flex;
  align-items: center;
  line-height: normal;
  height: 100%;
  overflow: hidden;
  img {
    animation-name: fade-in-up;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: both;
  }

  img:first-child {
    line-height: normal;
    margin-top: 0px;
    height: 55px;
    width: 55px;
    margin-right: 8.5px;
    padding: 5px;
    @media only screen and (max-width: 575px) {
      height: 40px;
      width: 40px;
    }
  }
  img:nth-child(2) {
    opacity: 0.8;
    @media only screen and (max-width: 575px) {
      height: 40px;
    }
  }

  @keyframes fade-in-up {
    0% {
      opacity: 0;
      transform: translateY(55px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }

    h2 {
      text-align: center;
      margin: 0;
      padding-left: 15px;
    }
  }
`;

export const HeaderLinks = styled.span`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
`;

export const HideOnMobile = styled(Item)`
  @media only screen and (min-width: 767px) {
    display: none;
  }
`;

export const B2BMenuItem = styled(Item)`
  svg {
    vertical-align: middle;
    margin-right: 0.5em;
  }
`;

export const GoatBg = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${props => props.theme.color.primary};
  border-radius: ${props => props.theme.borderRadius.md};
  margin-right: 8.5px;
`;

export const GoatBgWrapper = styled(Col)`
  overflow: hidden;
  height: 55px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Goat = styled.img`
  height: 100%;
  width: 100%;
  display: block;
`;

export const FlushRight = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
`;

export const ConsumerLink = styled.a`
  margin-left: auto;
  a {
    color: ${props => props.theme.color.grey87};
    font-size: 15px;
    margin-left: 1em;
    svg {
      margin-right: 0.25em;
      font-size: 1.25em;
      vertical-align: text-bottom;
    }
    &:hover {
      color: ${props => props.theme.color.tertiary};
    }
  }
`;

export const DropdownHeader = styled.div`
  text-align: center;
  margin: 1em 0 0.5em 0;
  line-height: 1.25em;
  .name {
    color: black;
    font-size: 16px;
  }
  .profile-link a {
    color: ${props => props.theme.color.grey45};
    font-size: 13px;
  }
  .subtitle {
    color: ${props => props.theme.color.grey45};
    font-size: 13px;
  }
  a &:hover {
    color: ${props => props.theme.color.secondary};
  }
`;

export const IconCircle = styled(StyledIconCircle)`
  background-color: ${props => props.theme.color.secondary};
  color: white;
  margin-right: 0.5em;
  width: 24px;
  height: 24px;
`;

export const IconCirclePrimary = styled(IconCircle)`
  background-color: ${props => props.theme.color.tertiary};
`;

export const IconCirclePlain = styled(StyledIconCircle)`
  background-color: white;
`;

export const ButtonContentDesktop = styled.span`
  &&& {
    @media only screen and (max-width: 574px) {
      display: none;
    }
  }
`;

export const ButtonContentMobile = styled.span`
  &&& {
    @media only screen and (min-width: 575px) {
      display: none;
    }
  }
`;

export const MobileItem = styled(Item)`
  &&& {
    @media only screen and (min-width: 575px) {
      display: none;
    }
  }
`;

export const MobileDivider = styled(Divider)`
  &&& {
    @media only screen and (min-width: 575px) {
      display: none;
    }
  }
`;
