import styled from 'styled-components';

// keep bottom padding equal to footer height
const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 110px; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: ${props => {
    if (props.omitHeader) {
      return 0;
    } else if (props.smallHeader) {
      return '55px';
    } else {
      return '110px';
    }
  }};
`;

Main.displayName = 'Main';

export default Main;
