import styled from 'styled-components';
import BoxContainer from './BoxContainer';

const ListCard = styled(BoxContainer)`
  border-radius: 25px 25px 0 0;
  padding: 1em 0 0 0;
  background-color: white;
  height: 100%;
  box-shadow: ${props => props.theme.boxShadow.card};
  overflow: hidden;
`;

export default ListCard;
