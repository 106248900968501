import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { FaBars, FaChevronDown, FaSignOutAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Store } from '../../store';
import rgTheme from '../../styles/rgTheme';
import { Avatar, Button, Col, Dropdown, Menu } from '../Ant';
import { HoverButton, OutlinedHoverButton } from '../Ant/Buttons/';
import { DropdownHeader, HeaderLinks, IconCirclePlain } from './styles';

// eslint-disable-next-line no-unused-vars
const AuthMenu = ({ full, onMap }) => {
  const toastNotification = useToasts();
  const [active, setActive] = useState(null);
  const router = useRouter();
  const dispatch = useDispatch();
  const authState = useSelector(Store.auth.selectors.get);
  const isAuthenticated = authState.isAuthenticated;
  const user = useSelector(Store.users.selectors.getLoggedUser()) || { attributes: {} }; // FIXME _app.js load logged user.

  const handleLogout = () => {
    return dispatch(Store.auth.actions.deauthenticate(isAuthenticated, toastNotification)).then(
      () => {
        return router.push('/');
      }
    );
  };

  const routes = [
    'trip-planner',
    'my-travel-map',
    'sync',
    'leaderboard',
    'find-friends',
    'me/settings',
  ];

  useEffect(() => {
    if (router.pathname.length !== 0) {
      const path = router.pathname.substring(1);
      if (routes.includes(path)) {
        setActive(path);
      }
    }
    // routes.includes();
  }, []);

  const userSlug = isAuthenticated ? authState.tokenInfo.slug : null;

  return (
    <>
      <HeaderLinks>
        {/* ON MAIN PAGE IN DESKTOP MODE, SHOW TP, MTM, BLOG BUTTONS */}
        {!onMap && (
          <Col xs={0} sm={0} md={24} lg={24} style={{ flex: 'auto' }}>
            <HoverButton href="/trip-planner">Trip Planner</HoverButton>
            <HoverButton href="/my-travel-map/edit">My Travel Map</HoverButton>
            <HoverButton href="/blog">Blog</HoverButton>
          </Col>
        )}

        <Dropdown
          // DROPDOWN MENU
          overlay={
            <Menu defaultSelectedKeys={[active]}>
              {/* DROPDOWN MENU HEADER. IF AUTH, SHOW USER PHOTO. ELSE SHOW RG LOGO */}
              {isAuthenticated && (
                <>
                  <DropdownHeader className="blue">
                    {!onMap && (
                      <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                        <Avatar
                          size={50}
                          src={_get(
                            user || {},
                            'attributes.avatar.avatar',
                            `https://source.boringavatars.com/pixel/100/${user.attributes.name}?colors=ff6daa,5A71FF,f8bd2c,2D3988,f0d6a5`
                          )}
                        />
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                      <div className="name">{user.attributes.name}</div>
                    </Col>
                    {onMap && <div className="name">{user.attributes.name}</div>}
                    <div>
                      <Link href={`/travelers/${userSlug}`}>
                        <a className="subtitle">See my profile</a>
                      </Link>
                    </div>
                  </DropdownHeader>
                  <Menu.Divider key="menu-divider" />
                </>
              )}

              {/* IF ON MAP OR MOBILE, INCLUDE KEYITEMS IN DROPDOWN MENU */}
              <Menu.Item key="trip-planner" className={!onMap && 'hide-on-desktop'}>
                <Link href="/trip-planner">
                  <a style={{ color: 'black' }}>
                    <img
                      src="/images/shared/icons/binoculars.png"
                      height="28"
                      alt="binoculars"
                      style={{ margin: '0 1em 0 0' }}
                    />
                    Trip Planner
                  </a>
                </Link>
              </Menu.Item>

              <Menu.Item key="my-travel-map" className={!onMap && 'hide-on-desktop'}>
                <Link href="/my-travel-map/edit">
                  <a style={{ color: 'black' }}>
                    <img
                      src="/images/shared/icons/pin-map.png"
                      height="28"
                      alt="pin map"
                      style={{ margin: '0 1em 0 0' }}
                    />
                    <span>My Travel Map</span>
                  </a>
                </Link>
              </Menu.Item>
              {/* ALWAYS IN DROPDOWN MENU */}
              <Menu.Item key="sync">
                <Link href="/sync">
                  <a>
                    <img
                      src="/images/shared/icons/sync.png"
                      height="28"
                      alt="sync"
                      style={{ margin: '0 1em 0 0' }}
                    />
                    Sync Travels
                  </a>
                </Link>
              </Menu.Item>
              <Menu.Item key="leaderboard">
                <Link href="/leaderboard">
                  <a>
                    <img
                      src="/images/shared/icons/leaderboard.png"
                      height="28"
                      alt="leaderboard"
                      style={{ margin: '0 1em 0 0' }}
                    />
                    Leaderboard
                  </a>
                </Link>
              </Menu.Item>
              {/* ALWAYS IN AUTH DROPDOWN MENU */}
              {isAuthenticated && (
                <>
                  <Menu.Item key="find-friends">
                    <Link href="/find-friends">
                      <a>
                        <img
                          src="/images/shared/icons/contacts.png"
                          height="28"
                          alt="contacts"
                          style={{ margin: '0 1em 0 0' }}
                        />
                        Find Friends
                      </a>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="me/settings">
                    <Link href="/me/settings">
                      <a>
                        <img
                          src="/images/shared/icons/privacy.png"
                          height="28"
                          alt="privacy settings"
                          style={{ margin: '0 1em 0 0' }}
                        />
                        Settings & Privacy
                      </a>
                    </Link>
                  </Menu.Item>
                </>
              )}
              {isAuthenticated && <Menu.Divider />}
              {isAuthenticated && (
                <Menu.Item key="logout" onClick={handleLogout}>
                  <IconCirclePlain>
                    <FaSignOutAlt />
                  </IconCirclePlain>
                  Sign Out
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          {/* TRIGGERS */}
          {/* IF ON MAP, AUTH HAS AVATAR AND BARS. UNAUTH HAS BARS */}
          {onMap ? (
            <span
              className="ant-dropdown-link"
              style={{ cursor: 'pointer' }}
              onClick={e => e.preventDefault()}
            >
              <FaBars
                style={{
                  fontSize: '2em',
                  verticalAlign: 'middle',
                  color: rgTheme.color.grey67,
                  marginRight: '0.25em',
                }}
              />
              {isAuthenticated && (
                <Avatar
                  src={
                    user && user.attributes.avatar_url
                      ? user.attributes.avatar_url
                      : `https://source.boringavatars.com/pixel/64/${user.attributes.name}?colors=ff6daa,5A71FF,f8bd2c,2D3988,f0d6a5`
                  }
                />
              )}
            </span>
          ) : isAuthenticated ? (
            <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {/* IF ON MAIN SITE, AUTH HAS NAME AND ICON IN DESKTOP AND AVATAR AND BARS ON MOBILE */}
              <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
                <HoverButton>
                  {user.attributes.name}&nbsp;
                  <FaChevronDown />
                </HoverButton>
              </Col>
              <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} style={{ cursor: 'pointer' }}>
                <Avatar
                  src={
                    user && user.attributes.avatar_url
                      ? user.attributes.avatar_url
                      : `https://source.boringavatars.com/pixel/64/${user.attributes.name}?colors=ff6daa,5A71FF,f8bd2c,2D3988,f0d6a5`
                  }
                  style={{ margin: '0 0.25em 0 1em' }}
                />
                <FaBars
                  style={{
                    fontSize: '1.5em',
                    verticalAlign: 'text-bottom',
                  }}
                />
              </Col>
            </div>
          ) : (
            // IF ON MAIN SITE AND UNAUTH, ONLY SHOW BARS IF MOBILE
            <Col
              xs={24}
              sm={24}
              md={0}
              lg={0}
              xl={0}
              xxl={0}
              style={{
                flex: 'auto',
              }}
              className="ant-dropdown-link"
              onClick={e => e.preventDefault()}
            >
              <HeaderLinks>
                <HoverButton>
                  <FaBars style={{ fontSize: '1.5em' }} />
                </HoverButton>
              </HeaderLinks>
            </Col>
          )}
        </Dropdown>
      </HeaderLinks>

      {/* SIGN IN BUTTONS IF UNAUTH */}
      {!isAuthenticated && (
        <>
          {onMap ? (
            <Link href="/users/login">
              <a>
                <Button boxshadow color="primary">
                  Sign In
                </Button>
              </a>
            </Link>
          ) : (
            <>
              <HeaderLinks>
                <OutlinedHoverButton
                  href="/users/login"
                  style={{ lineHeight: '30px', marginLeft: '0.5em' }}
                >
                  Sign In
                </OutlinedHoverButton>
              </HeaderLinks>
            </>
          )}
        </>
      )}
    </>
  );
};

AuthMenu.propTypes = {
  full: PropTypes.bool,
  onMap: PropTypes.bool,
};

export default AuthMenu;
