import styled from 'styled-components';

const Buttons = styled.div`
  .ant-btn {
    margin-right: ${props => (props.lg ? '1em' : '0.25em')};
    margin-bottom: ${props => (props.lg ? '0.5em' : '0.25em')};
  }
`;

export default Buttons;
