import styled, { css } from 'styled-components';
import { AutoComplete as RawAutoComplete, Formik } from '../Ant';

const autoCompleteStyle = css`
  transition: all 0.1s;
  .ant-select-dropdown {
    padding: 0;
  }
  .ant-input-affix-wrapper {
    &:hover {
      background-color: ${props => props.hoveredit && props.theme.bg.default};

      input {
        background-color: ${props => props.hoveredit && props.theme.bg.default};
        cursor: ${props => props.hoveredit && 'pointer'};
      }
    }
    padding: ${props => props.hoveredit && '0'};
    cursor: ${props => props.hoveredit && 'pointer'};
    border: none !important;
    box-shadow: none !important;
  }
  input {
    border-radius: ${props => props.hoveredit && props.theme.borderRadius.md};
    box-shadow: none;
    border-color: transparent;
    padding: ${props => props.hoveredit && '4px 11px !important'};
    &:focus,
    &:active,
    &:hover {
      box-shadow: none;
      border-color: transparent;
    }
    &:focus,
    &:active {
      cursor: ${props => props.hoveredit && 'text'};
      background-color: ${props => props.hoveredit && props.theme.bg.default};
    }
  }

  .ant-select-item-option-content div {
    color: ${props => props.theme.color.secondary};
  }

  ${props =>
    props.borderless &&
    css`
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
    `}
`;

export const AntAutoComplete = styled(RawAutoComplete)`
  ${autoCompleteStyle}
`;
export const FormikAutoComplete = styled(Formik.AutoComplete)`
  ${autoCompleteStyle}
`;

export const Image = styled.img`
  border-radius: 7.5px;
  width: 85px;
  height: 50px;
  object-fit: cover;
  margin-left: auto;
`;
