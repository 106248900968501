import styled from 'styled-components';
import { Layout } from '../Ant';

//Icons
import { FaHeart } from 'react-icons/fa';
import { FaProductHunt } from 'react-icons/fa';
import { FaSignInAlt } from 'react-icons/fa';

const { Footer } = Layout;

export const StyledFooter = styled(Footer)`
  font-size: 15px;
  padding-top: 28px;
  padding-bottom: 14px;
  min-height: 100px;
  text-align: center;
  z-index: 100;
  color: white;
  width: 100%;

  a {
    color: white;
    /* Social links */
    svg {
      width: 1.3em;
      height: 1em;
      margin: 0em 0.25rem 0em 0em;
      vertical-align: middle;
      font-size: 1.5em;
      color: white;
    }
  }
  .footer-links {
    a {
      white-space: nowrap;
      padding: 4px 8px;
      color: white;
      &:hover {
        background-color: #555555;
        border-radius: 7000px;
        opacity: 1;
        padding: 4px 8px;
        color: inherit;
      }
    }
  }
  h5 {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const StyledCopyright = styled.span`
  font-size: 14px;
  font-weight: bold;
  opacity: 0.4;
  padding-bottom: 14px;
`;

export const StyledCredit = styled.span`
  font-weight: bold;
`;

export const StyledHeart = styled(FaHeart)`
  color: ${props => props.theme.color.primary};
`;

export const StyledSocialLinksArea = styled.div`
  display: inline-block;
  white-space: nowrap;
  margin: 1em 0em 1em;
  margin-right: 3px;
  color: ${props => props.theme.color.tertiary};
`;

export const StyledSocialLinks = styled.a`
  white-space: nowrap;
  margin-bottom: 15px;
  margin-top: 18px;
`;

export const StyledProducthunt = styled(FaProductHunt)`
  width: 1.3em;
  height: 1em;
  margin: 0em 0.25rem 0em 0em;
  vertical-align: middle;
  font-size: 1.5em;
  color: #d7e4ff;
`;

export const StyledDeveloperSite = styled.a`
  &&& {
    white-space: nowrap;
    border-radius: 7000px;
    padding: 4px 8px;
    margin-top: 300px;

    &:hover {
      background-color: #555555;
      text-decoration: none;
      opacity: 1;
      padding: 4px 8px;
      color: inherit;
    }
  }
`;

export const StyledSignIn = styled(FaSignInAlt)`
  color: white;
  margin: 0em 0.25rem 0em 0em;
`;

export const StyledParagraph = styled.p`
  opacity: 0.4;
  margin-bottom: 0.4em;
`;
