import styled, { css } from 'styled-components';

const FluidContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  ${props =>
    props.grey &&
    css`
      background-color: ${props => props.theme.bg.default};
      width: 100%;
    `}
`;

export default FluidContainer;
