import styled, { css } from 'styled-components';

const BoxContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.bg.default};
  width: 100%;
  margin: 0 0 1em 0;
  padding: 1em;
  border-radius: 10px;
  font-size: 1rem;
  border: none;

  ${props =>
    props.light &&
    css`
      background-color: ${props => props.theme.bg.light};
    `}
`;

export default BoxContainer;
