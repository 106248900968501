import _isEmpty from 'lodash/isEmpty';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';
import { Main } from '../components/Container';
import { DisableScroll } from '../styles';
import Footer from './Footer';
import GlobalAlerts from './GlobalAlerts';
import Header from './Header';
import HeaderSearch from './HeaderSearch';
import { mediaStyle } from './media';

const Layout = ({
  provider,
  children,
  pathname,
  user,
  omitHeader,
  smallHeader,
  omitHeaderSearch,
  omitFooter,
  scroll = true,
}) => (
  <>
    <Head>
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link
        href="https://fonts.googleapis.com/css?family=Lato:700|Quicksand:700&display=swap"
        rel="stylesheet"
      ></link>

      <style type="text/css" dangerouslySetInnerHTML={{ __html: mediaStyle }} />
    </Head>
    {!scroll && <DisableScroll />}
    {omitHeader ? null : (
      <div>
        <Header provider={provider} pathname={pathname} user={user} />
        {!_isEmpty(provider) || omitHeaderSearch ? null : <HeaderSearch />}
      </div>
    )}
    <Main
      omitHeader={omitHeader}
      smallHeader={smallHeader || !_isEmpty(provider)}
      omitHeaderSearch={omitHeaderSearch || !_isEmpty(provider)}
    >
      <GlobalAlerts />
      {children}
    </Main>
    {omitFooter || !_isEmpty(provider) ? null : <Footer />}
  </>
);

Layout.propTypes = {
  pathname: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  provider: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  user: PropTypes.string,
  image: PropTypes.string,
  omitHeader: PropTypes.bool,
  smallHeader: PropTypes.bool,
  omitHeaderSearch: PropTypes.bool,
  omitFooter: PropTypes.bool,
  scroll: PropTypes.bool,
};

export default Layout;
