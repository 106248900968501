import styled from 'styled-components';
import AutoComplete from '../AutoComplete';
import { FaSearch } from 'react-icons/fa';

export const Wrapper = styled.div`
  width: 100%;
  height: 55px;
  background-color: #ff9dc5;
  color: black;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  z-index: 999;
  input::placeholder {
    color: initial;
    opacity: 0.65;
  }
`;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  border-radius: 1000px;
  overflow: hidden;
  margin-top: 9px;
  padding: 0;
  display: flex;
  flex-direction: column;

  .ant-select-selector {
    border-radius: 1000px;
  }
  .ant-select-dropdown {
    padding: 0;
  }
  .ant-select-selector-search {
    border-radius: 1000px;
  }
`;

export const Image = styled.img`
  border-radius: 7.5px;
  width: 85px;
  height: 50px;
  object-fit: cover;
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 12px;
  top: 10.5px;
  color: #bfbfbf;

  /* &:hover{
    visibility: hidden;
  } */
`;
