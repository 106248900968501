import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StickyWrapper } from './styles';

const Sticky = ({ children, isSearching, top }) => {
  const [sticky, setSticky] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleUserScroll = event => {
      if (event.target.documentElement.scrollTop >= top) setSticky(true);
      else setSticky(false);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleUserScroll);
      return () => {
        window.removeEventListener('scroll', handleUserScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StickyWrapper isSticky={!isSearching && sticky} top={top} ref={ref}>
      {children}
    </StickyWrapper>
  );
};

Sticky.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSearching: PropTypes.bool,
};

export default Sticky;
