import React from 'react';
import Link from 'next/link';

import {
  StyledFooter,
  StyledCopyright,
  StyledCredit,
  StyledHeart,
  StyledSocialLinks,
  StyledSocialLinksArea,
} from './styles';
import { FaFacebookSquare, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Row, Col, Title } from '../Ant';

const Footer = () => {
  return (
    <StyledFooter>
      <Row justify="center" className="footer-links">
        <Col xs={24} sm={6} lg={4}>
          <Title level={5}>Our Organization</Title>
          <div>
            <Link href="/about-us">
              <a>About Us</a>
            </Link>
          </div>
          <div>
            <Link href="/careers">
              <a>Careers</a>
            </Link>
          </div>
          <div>
            <Link href="/press">
              <a>Press</a>
            </Link>
          </div>
          <div>
            <Link href="/contact-us">
              <a>Contact Us</a>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={6} lg={4}>
          <Title level={5}>RoadGoat.com</Title>
          <div>
            <a href="https://roadgoat.kampsite.co/" target="_blank" rel="noreferrer">
              🌟 Vote on New Features
            </a>
          </div>
          <div>
            <Link href="/faq">
              <a>Your Privacy</a>
            </Link>
          </div>
          <div>
            <Link href="/faq">
              <a>FAQs</a>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={6} lg={4}>
          <Title level={5}>For Developers</Title>
          <Link href="/business/cities-api">
            <a>Cities API</a>
          </Link>
        </Col>
        <Col xs={24} sm={6} lg={4}>
          <Title level={5}>Legal</Title>
          <div>
            <Link href="/terms-and-conditions">
              <a>Terms</a>
            </Link>
          </div>
          <div>
            <Link href="/privacy-policy">
              <a>Privacy Policy</a>
            </Link>
          </div>
        </Col>
      </Row>

      <a
        href="https://www.producthunt.com/posts/roadgoat?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-roadgoat"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=320411&theme=dark"
          alt="RoadGoat - Find your perfect travel place | Product Hunt"
          style={{ width: '250px', height: '54px', marginTop: '1em' }}
          width="250"
          height="54"
        />
      </a>

      <div>
        <StyledSocialLinksArea>
          <StyledSocialLinks target="_blank" rel="noreferrer" href="https://facebook.com/roadgoat/">
            <FaFacebookSquare />
          </StyledSocialLinks>

          <StyledSocialLinks
            href="https://twitter.com/goRoadGoat/"
            target="_blank"
            rel="noreferrer"
          >
            <FaTwitter />
          </StyledSocialLinks>

          <StyledSocialLinks
            href="https://instagram.com/goRoadGoat/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </StyledSocialLinks>
        </StyledSocialLinksArea>
      </div>

      <div>
        <StyledCredit>
          Made with <StyledHeart /> in New York, NY
        </StyledCredit>
      </div>
      <div>
        <StyledCopyright>&#169; 2018-2021 HashTravel, Inc. All rights reserved.</StyledCopyright>
      </div>
    </StyledFooter>
  );
};

export default Footer;
