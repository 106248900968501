import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Input } from '../Ant';
import { Container } from '../Container';
import { StyledAutoComplete, Wrapper, SearchIcon } from './styles';
import Sticky from '../Sticky';

const HeaderSearch = () => {
  const router = useRouter();
  const [focus, setFocus] = useState(false);

  const handleResultSelect = (_value, option) => {
    const browseDestinationLink = {
      pathname: '/trip-planner',
      query: {
        id: option.destination.attributes.slug,
      },
    };

    const destinationLink = {
      pathname: `/travel-guides/${option.destination.attributes.slug}`,
    };

    router.push(
      option.destination.attributes.destination_type === 'City'
        ? destinationLink
        : browseDestinationLink
    );
  };

  if (router.pathname !== '/') {
    return (
      <Sticky top={55} isSearching={focus}>
        <Wrapper className="sticky-header">
          <Container id="search-area">
            <StyledAutoComplete
              type="full"
              allowClear="false"
              handleSelect={handleResultSelect}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              getPopupContainer={() => document.getElementById('search-area')}
            >
              <Input
                style={{ fontSize: '16px', backgroundColor: '#ffe7f1' }}
                name="name"
                placeholder="Where to, master?"
              />
            </StyledAutoComplete>
            {!focus && <SearchIcon />}
          </Container>
        </Wrapper>
      </Sticky>
    );
  } else {
    return <div />;
  }
};

export default HeaderSearch;
