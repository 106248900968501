import styled, { css } from 'styled-components';
//import { deviceQueries as device } from '../utils/deviceQueries';

// TODO SWITCH TO SAME DEVICE WIDTHS ACROSS APP
const Container = styled.div`
  display: block;
  width: 100%;
  max-width: 100%;
  position: relative;

  @media only screen and (max-width: 767px) {
    width: auto;
    margin-left: 1em;
    margin-right: 1em;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 723px;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 933px;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 1200px) {
    width: 1127px;
    margin-left: auto;
    margin-right: auto;
  }

  ${props =>
    props.body &&
    css`
      position: relative;
      margin-top: 2em;
      padding-bottom: 2em;
    `}

    ${props =>
      props.center &&
      css`
        text-align: center;
      `}

  ${props =>
    props.grey &&
    css`
      background-color: ${props => props.theme.bg.default};
      width: 100%;
    `}
`;

export default Container;
