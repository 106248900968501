import styled from 'styled-components';

const FieldContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto 1fr;
  grid-auto-rows: max-content;

  span {
    align-self: center;
  }
`;

FieldContainer.displayName = 'FieldContainer';

export default FieldContainer;
