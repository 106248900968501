import { Row } from 'antd';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import numeral from 'numeral';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { Store } from '../../store';
import { AntAutoComplete, FormikAutoComplete, Image } from './styles';
const KEY_WINDOW = 'autoComplete';

const AutoComplete = ({
  children,
  type,
  formik = false,
  handleSelect,
  handleClear,
  ...rawProps
}) => {
  const dispatch = useDispatch();
  const destinations = useSelector(
    Store.destinations.selectors.getDestinationsByKeyWindow(KEY_WINDOW)
  );

  const searchDestinations = query => {
    dispatch(Store.destinations.actions.searchByAutoComplete(query, KEY_WINDOW));
  };

  const searchDestinationsDebounce = useRef(debounce(200, searchDestinations));

  const onChange = query => {
    if (!_isEmpty(query) && query.length > 2) {
      searchDestinationsDebounce.current(query);
    } else if (_isUndefined(query) && handleClear) {
      handleClear();
    }
  };

  const renderTitle = title => <span>{title}</span>;

  const fullItem = destination => {
    const featuredPhoto = destination.relationships.featured_photo;
    return (
      <div id={`auto-complete-item-${destination.id}`}>
        <Row>
          <div className="grey">
            <div className="black">{destination.attributes.name}</div>
            {numeral(destination.attributes.check_in_count).format('0,0')}&nbsp;
            {pluralize('traveler', destination.attributes.check_in_count)}
          </div>
          <Image
            className="lazyload"
            data-src={
              featuredPhoto
                ? featuredPhoto.attributes.image.thumb
                : '/images/shared/placeholders/place-searchpage.png'
            }
            alt={destination.attributes.name}
          />
        </Row>
      </div>
    );
  };

  const smallItem = destination => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        id={`auto-complete-item-${destination.id}`}
      >
        {destination.attributes.name}
        <span>
          {numeral(destination.attributes.check_in_count).format('0,0')}&nbsp;
          {pluralize('traveler', destination.attributes.check_in_count)}
        </span>
      </div>
    );
  };

  const renderItem = destination => {
    return {
      value: destination.attributes.name,
      destination: destination,
      label: type === 'full' ? fullItem(destination) : smallItem(destination),
    };
  };

  const sections = _groupBy(destinations.data, 'attributes.destination_type');
  const sectionTranslator = {
    State: 'US States',
    City: 'Cities',
    Country: 'Countries',
    Region: 'Regions',
  };

  const options = _map(sections, (values, section) => {
    return {
      label: renderTitle(sectionTranslator[section] || section),
      options: values.map(renderItem),
    };
  });

  const onInternalChange = value => {
    if (_isUndefined(value)) {
      handleClear();
    }
  };

  const buildAutoCompleteProps = () => {
    return {
      allowClear: true,
      notFoundContent: null,
      options,
      onChange: onInternalChange,
      onSearch: onChange,
      onSelect: handleSelect,
      ...rawProps,
    };
  };

  return formik ? (
    <FormikAutoComplete {...buildAutoCompleteProps()}>{children}</FormikAutoComplete>
  ) : (
    <AntAutoComplete {...buildAutoCompleteProps()}>{children}</AntAutoComplete>
  );
};

AutoComplete.propTypes = {
  type: PropTypes.string,
  formik: PropTypes.bool,
  children: PropTypes.element,
  handleSelect: PropTypes.func,
  handleClear: PropTypes.func,
};

AutoComplete.defaultProps = {
  type: 'small',
};

export default AutoComplete;
