import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '../Ant';
import ConditionalWrapper from './ConditionalWrapper';
import styled, { css } from 'styled-components';
// import Link from 'next/link';

const Content = styled.div`
  color: ${props => props.theme.color.grey75};
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props => (props.background ? props.background : props.theme.bg.default)};
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  padding: 0.5em;
  margin-left: -0.5em;
  .ant-avatar,
  svg:not(.ant-progress-circle) {
    margin-right: 0.5em;
    flex-shrink: 0;
  }
  line-height: 18px;
  transition: all 0.2s ease;
  ${props =>
    props.url &&
    css`
      cursor: pointer;
      :hover {
        background-color: #e0e1e2;
      }
    `}

  .rounded-square {
    color: blue;
    border-radius: 10px !important;
  }
  .ant-avatar {
    border-radius: 0;
    img {
      object-fit: contain;
    }
  }
`;

// const GreyLink = styled.a`
//   color: ${props => props.theme.color.grey75};
// `;

const Feature = ({ url, imageUrl, imageAlt, content, background, showImage }) => {
  return (
    <ConditionalWrapper
      condition={url}
      wrapper={children =>
        url.includes('http') ? (
          url.includes('kayak') || url.includes('vrbo') ? (
            <a href={url} target="_blank" rel="noopener noreferrer sponsored">
              {children}
            </a>
          ) : (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
        ) : (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a rel="noopener" target="_blank" href={url}>
            {children}
          </a>
        )
      }
    >
      <Content background={background} url={url}>
        <>
          {showImage != false && (
            <Avatar shape="square" size="small" src={imageUrl} alt={imageAlt} />
          )}
          {content}
        </>
      </Content>
    </ConditionalWrapper>
  );
};

Feature.propTypes = {
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  content: PropTypes.element,
  background: PropTypes.string,
  showImage: PropTypes.bool,
};

export default Feature;
