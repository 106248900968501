import React from 'react';
import { Alert } from '../Ant';
import { useRouter } from 'next/router';

const GlobalAlerts = () => {
  const router = useRouter();
  const errorMessage = router.query._error;

  return (
    <>
      {errorMessage && (
        <Alert
          message="Oops, someting went wrong!"
          description={errorMessage}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default GlobalAlerts;
